﻿.data-list-striped {
    .data-list-cell {
        flex: 0 0 80px;
        max-width: 80px;
        min-width: 80px;
        box-sizing: border-box;
        align-self: center;
        overflow: hidden;
        position: relative;

        &-text-full {
            white-space: normal;
        }

        &.error {
            overflow: visible;
            box-shadow: 0 0 0 1px $color-error;
            border: solid 1px $color-error;

            .form-error {
                top: 100%;
                left: -2px;
            }
        }

        &.popover-column {
            overflow: visible;
        }

        &-auto {
            flex: auto;
            max-width: none;
            min-width: auto;
        }

        &-symbol {
            @include flex-basis(10px);
            padding: 0;
            text-align: center;
        }

        &-icon {
            @include flex-basis(16px);
            padding: 0;
        }

        &-xxxs {
            @include flex-basis(24px);
        }

        &-xxs {
            @include flex-basis(32px);
        }

        &-xxs-02 {
            @include flex-basis(48px);
        }

        &-xs {
            @include flex-basis(60px);
        }

        &-xs-sm {
            @include flex-basis(75px);
        }

        &-sm-01 {
            @include flex-basis(100px);
        }

        &-sm {
            @include flex-basis(110px);
        }

        &-md {
            @include flex-basis(125px);
        }

        &-lg {
            @include flex-basis(150px);
        }

        &-lg-02 {
            @include flex-basis(180px);
        }

        &-xl {
            @include flex-basis(200px);
        }

        &-xl-xxl {
            @include flex-basis(240px);
        }

        &-xxl {
            @include flex-basis(280px);
        }

        &-xxxl {
            @include flex-basis(315px);
        }

        &-xxxxl {
            @include flex-basis(400px);
        }

        &-trans-status {
            flex: 0 0 81px;
            max-width: 81px;
            min-width: 81px;
        }

        &-xs-flexible {
            flex: 1 1 60px;
            min-width: 60px;
            max-width: none;
        }

        &-md-flexible {
            flex: 1 1 125px;
            min-width: 125px;
            max-width: none;
        }

        &-lg-02-flexible {
            flex: 1 1 180px;
            min-width: 180px;
            max-width: none;
        }

        &-xl-flexible {
            flex: 1 1 200px;
            min-width: 200px;
            max-width: none;
        }

        &-xxl-flexible {
            flex: 1 1 280px;
            min-width: 280px;
            max-width: none;
        }

        &-xxxl-flexible {
            flex: 1 1 315px;
            min-width: 315px;
            max-width: none;
        }

        &-placeholder {
            @include flex-basis(330px);
        }
    }
}

.data-list-cell-collapse-action {
    @include flex-basis(24px);

    &.btn-link .icon-svg {
        margin-right: 0;
    }
}
