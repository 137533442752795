﻿.container-sidebar {
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: $white-1;
    width: 100%;
    max-width: 100%;
}

.content-part-sidebar {
    width: calc(100% - 360px);
}

.sidebar {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border-color;
    width: 359px;

    &.collapsed {
        width: 40px;
        overflow: hidden;

        .sidebar-header {
            border-bottom-color: transparent;
        }

        .sidebar-header-title,
        .sidebar-body {
            display: none;
        }

        .btn-sidebar-expand .icon-svg {
            transform: rotate(180deg);
        }
    }

    &-header {
        flex: none;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
    }

    &-header-btn {
        height: 48px;
        line-height: 46px;
    }

    &-header-title {
        margin: 0 0 0 24px;
    }

    &-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        min-height: 1px;
    }

    &-title-type01 {
        h2 {
            display: flex;
            align-items: center;
            padding: 20px 24px;
            line-height: 22px;
            margin: 0;
        }

        .icon-svg {
            margin-right: 12px;
        }
    }

    &-nav-type01 {
        li {
            font: 14px/20px $font-roboto-regular;
            padding: 10px 20px 10px 52px;
            cursor: pointer;
            @extend .transition;
            position: relative;

            &.active {
                background: $row-active;
                font-family: $font-roboto-medium;
            }

            &:hover {
                background: $primary-6;
            }

            &:active,
            &:focus {
                background: $row-active;
                color: $link-focus;
            }

            .icon-warning {
                position: absolute;
                top: 10px;
                right: 20px;
                margin: 0;
            }
        }
    }
}
