﻿.page-content-profile {
    .profile-section {
        flex-direction: row;
    }

    .profile-list {
        display: flex;
        flex-flow: row wrap;
        flex: none;
        line-height: 28px;
        margin: 0;

        &.style01 {
            margin-bottom: 24px;
        }

        dt {
            flex-basis: 165px;
            color: $grey-8;
            margin-bottom: 4px;
        }

        dd {
            width: calc(100% - 165px);
            margin: 0 0 4px;
            white-space: nowrap;

            .display-inline {
                max-width: 100%;
            }

            .text-ellipsis {
                width: auto;
            }

            &.email {
                white-space: normal;
            }

            &.linkedin-link {
                max-width: 280px;
            }
        }

        .btn-sm {
            margin: 0 0 0 12px;
        }

        .loader-box {
            height: 24px;
        }
    }

    .profile-content {
        overflow: auto;
        display: flex;
        flex: auto;

        .tab-pane {
            flex-direction: column;
            width: 100%;
        }

        .sub-title {
            border-bottom: 1px solid $border-color;
            padding: 12px 24px;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex: none;
            line-height: 32px;

            h1 {
                margin: 0;
                line-height: 24px;
            }

            .control {
                display: flex;
                align-items: center;

                .btn {
                    margin-left: 8px;
                }
            }
        }

        .content-section {
            padding: 32px 24px 24px;

            .collapsable-panel-cnt {
                padding: 16px 0 0;
            }

            .show-info {
                margin: -16px 0 8px;
            }

            .intro-info {
                margin: -8px 0 16px;
                flex: none;

                .status-message-cnt {
                    padding-right: 48px;
                    line-height: 20px;

                    p {
                        margin-bottom: 8px;
                    }
                }

                .btn-close {
                    position: absolute;
                    top: 4px;
                    right: 16px;
                }
            }

            .section-title {
                border-bottom: 1px solid $border-color;
                margin: 24px 0 16px;
                padding-bottom: 9px;
                line-height: 22px;

                &:first-child {
                    margin-top: 0;
                }

                h2 {
                    margin: 0;
                }
            }
        }
        /* Personal tab */
        .personal-info {

            &-avatar {
                position: relative;
                width: 96px;
                height: 96px;
                margin-right: 65px;

                .user-avatar {
                    font-size: 96px;
                }

                .icon-svg {
                    position: absolute;
                    margin: 0;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                .container-loader {
                    border-radius: 50%;

                    .preloader {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            &-list {
                max-width: 80%;
                flex-grow: 1;

                .btn-edit {
                    margin-left: 8px;
                }

                .edit-form-wrapper {
                    max-width: 800px;
                    margin-bottom: 8px;
                }

                form {
                    border: 1px solid $primary-3;
                    padding: 7px 7px 7px 20px;
                    background: $grey-7;
                    border-radius: 4px;
                    display: flex;

                    div:not(.form-error) {
                        display: flex;
                    }

                    .form-control-wrapper {
                        padding-right: 22px;
                        flex-grow: 1;
                    }

                    .form-error {
                        top: 100%;
                        left: 0;
                    }

                    & > label {
                        min-width: 170px;
                        font-size: 14px;
                        white-space: nowrap;
                        line-height: 32px;
                    }

                    input {
                        width: 100%;
                    }

                    .btn {
                        margin-left: 10px;
                    }

                    .bloomberg-email {
                        position: relative;
                        width: 100%;

                        input {
                            width: 100%;
                            padding-right: 110px;
                            text-align: right;
                        }

                        label {
                            color: $grey-8;
                            position: absolute;
                            top: 6px;
                            right: 8px;
                        }

                        .form-control.is-invalid {
                            background-image: none;
                        }
                    }
                }
            }
        }
        /* Password tab */
        .password {

            .change-password-form {
                max-width: 445px;
                padding: 15px 44px 50px;

                .form-item {
                    margin-bottom: 18px;
                    position: relative;
                }
            }

            .empty-placeholder {
                margin: 0;
            }
        }
        /* TFA tab */
        .tfa {
            .content-section {
                padding-top: 16px;
            }

            .info {
                margin-bottom: 24px;
            }

            &-section {
                position: relative;
                flex-shrink: 0;

                &:not(:last-child) {
                    margin-bottom: 24px;
                }

                &.flex-row {
                    align-items: flex-start;
                }

                > .icon {
                    margin-right: 24px;
                }

                &-cnt {
                    flex-basis: 100%;

                    h2 {
                        margin-bottom: 16px;
                        border-bottom: 1px solid $border-color;
                        line-height: 40px;
                    }

                    > p {
                        color: $grey-8;
                        margin: 0 0 16px;
                        line-height: 1.6;
                    }

                    .contact-info {
                        margin-right: 8px;
                    }

                    .phone-area {
                        .btn-edit {
                            vertical-align: bottom;
                            margin-right: 24px;
                        }

                        .form-control {
                            width: 140px;
                            margin-right: 20px;

                            &.country-code {
                                width: 100px;
                                margin-right: 10px;
                            }
                        }

                        .drop-down-list {
                            @media screen and (max-height: 820px) {
                                top: auto;
                                bottom: 100%;
                                margin-bottom: 1px;
                            }

                            .section:nth-child(2) {
                                @media screen and (max-height: 1020px) {
                                    height: 200px;
                                }
                            }
                        }

                        .btn {
                            margin-right: 10px;
                        }

                        .status-message {
                            align-items: center;
                            margin-top: 24px;

                            .btn {
                                margin-right: 0;
                                margin-left: 10px;
                            }
                        }
                    }
                }

                .modal-confirm-phone {
                    p {
                        color: $grey-4;
                    }

                    .timer-area {
                        margin: 0 0 15px;
                    }

                    .re-send-section {
                        .timer {
                            display: inline-block;

                            .icon {
                                display: none;
                            }
                        }
                    }
                }

                .toggle {
                    position: absolute;
                    top: 12px;
                    right: 0;
                }
            }
        }
        /* Subscriptions tab*/
        .subscriptions {
            .content-section {
                padding-top: 16px;
            }
        }
        /* Trading Limits tab*/
        .trading-limits {
            .content-section {
                padding-top: 16px;

                .show-info {
                    margin-bottom: 0;
                    padding-top: 16px;
                    padding-bottom: 12px;

                    .btn-link {
                        vertical-align: top;
                    }
                }

                .intro-info {
                    margin-top: 0;
                }

                .section-title {
                    padding-top: 9px;
                }
            }
        }
    }

    .profile-row {
        display: flex;
        min-height: 44px;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }

    .profile-desc {
        flex: 0 0 200px;
        padding-right: 10px;
    }

    .profile-details {
        display: flex;
        flex-direction: column;
    }

    .profile-avatar {
        flex: 0 0 40px;
        margin-right: 16px;

        .user-avatar {
            font-size: 40px;
            margin-top: -6px;
            vertical-align: top;
        }
    }

    .profile-name {
        flex: 0 1 auto;
        margin-right: 10px;
    }

    .profile-email {
        flex: 0 0 35%;
        margin-right: 10px;
    }

    .profile-phone {
        flex: 0 0 20%;
        margin-right: 10px;
    }

    .profile-cntrl {
        flex: 0 0 125px;
    }

    .clo-managers-alerts,
    .banks-alerts {
        .control-search-wrapper {
            width: 364px;
            margin-bottom: 8px;
        }

        .data-list-container {
            padding-bottom: 17px;
        }

        .data-list-columns {
            max-height: calc(100vh - 385px);

            .data-column-sticky-left,
            .data-column-sticky-middle {
                height: 100%;
            }
        }

        .data-list.data-list-freeze .middle-sticky-content {
            min-width: 1352px;
        }

        .data-list-cell-control {
            display: flex;
            align-items: center;
            height: 100%;

            .display-inline {
                display: flex;
            }
            
            .radio {
                vertical-align: top;
            }
        }

        .data-list-cell-right-border {
            border-right: 1px solid $border-color;
        }

        .filter-name-text {
            padding-right: 12px;
        }
    }

    .banks-alerts {
        .data-list.data-list-freeze .middle-sticky-content {
            min-width: 1085px;
        }
    }
}
