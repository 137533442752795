.container-history {
    .sidebar-nav-history {
        .sidebar-nav-list-item {
            align-items: center;
            flex-wrap: wrap;
            padding: 0 24px 4px 16px;
    
            .btn-expand {
                align-self: stretch;
    
                svg {
                    margin-right: 0;
                    margin-top: 4px;
                }
            }
    
            &.expanded {
                .history-deal-updates {
                    white-space: normal;
                }
    
                .icon-svg-icon-expand {
                    transform: rotate(90deg);
                }
            }
    
            &.active {
                & + .history-content {
                    background-color: $primary-6;
                }
            }
    
            .history-box-head {
                flex-grow: 1;
                padding-top: 12px;
                padding-bottom: 8px;
            }
    
            .history-version {
                margin: 0 8px 0 12px;
            }
    
            .history-date {
                font-size: 12px;
                font-family: $font-roboto-regular;
            }
    
            .history-deal-updates {
                width: 100%;
                color: $orange-1;
                font-size: 12px;
                font-family: $font-roboto-medium;
                padding-left: 28px;
                white-space: nowrap;
            }
        }
    
        .history-nav-box {
            flex: none;
    
            .history-content {
                padding: 4px 12px 12px 44px;
                font-size: 12px;
                font-family: $font-roboto-regular;
                border-bottom: 1px solid $border-color;
            }
    
            .history-element {
                margin-bottom: 24px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
    
            h2 {
                font-size: 12px;
                font-family: $font-roboto-bold;
                margin-bottom: 12px;
            }
    
            .list-bullet {
                li {
                    margin-left: 8px;
    
                    &:not(:first-child) {
                        margin-top: 12px;
                    }
    
                    &:before {
                        background-color: $grey-4;
                    }
    
                    .increase-value,
                    .decrease-value {
                        margin-left: 4px;
                        margin-top: -2px;
                    }
    
                    .update-size {
                        &-to {
                            padding-right: 20px;
    
                            & + .icon-svg {
                                margin-left: -16px;
                            }
                        }
                    }
                }
            }
    
            .history-row {
                display: flex;
                justify-content: space-between;
                margin-top: 4px;
    
                .history-class-col {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
    
                & > span {
                    padding-right: 5px;
    
                    &:last-child {
                        padding-right: 0;
                    }
    
                    &:not(:first-child) {
                        font-family: $font-roboto-bold;
                        width: 38%;
                    }
    
                    &:first-child {
                        flex-grow: 1;
                        width: 24%;
                    }
                }
    
                & > .tooltip-wrapper {
                    padding-right: 5px;
                    font-family: $font-roboto-bold;
                    width: 36%;
    
                    &:last-child {
                        padding-right: 0;
                    }
    
                    .history-class-col {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        white-space: nowrap;
                    }
                }
    
                &.history-row-header {
    
                    & > span {
                        font-family: inherit;
                    }
                }
            }
        }
    
        .control-search-autocomplete {
            .search-lookup {
                width: calc(100% + 41px);
                border-top: none;
    
                td.col-main {
                    text-transform: none;
                }
            }
    
            .suggested-search {
                background-color: $grey-3;
                color: $grey-4;
                line-height: 24px;
                pointer-events: none;
                border-bottom: 1px solid $border-color;
                font-size: 12px;
                position: sticky;
                top: 0;

                &:before {
                    position: absolute;
                    content: '';
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $border-color;
                }
            }
        }
    }

    .difference-highlight {
        display: inline-flex;
        align-items: center;
        padding: 2px 8px;
        margin: 0 -8px;
        border-radius: 2px;
        max-width: 100%;
        min-height: 24px;
    
        &.added {
            background-color: $added;
        }
    
        &.updated {
            background-color: $updated;
        }
    
        &.removed {
            background-color: $removed;
    
            .difference-highlight-text {
                text-decoration: line-through;
            }
        }
    
        &-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
    
            .markdown-viewer {
                white-space: normal;
                margin-bottom: 0;
    
                p {
                    margin: 0;
    
                    & + p {
                        margin-top: 10px;
                    }
                }
            }
        }
    
        &-label {
            font-size: 12px;
            font-family: $font-roboto-medium;
            margin: 0;
        }
    }

    .increase-value {
        color: $green-2;
    }
    
    .decrease-value {
        color: $warning-3;
    }

    .data-list {
        .data-list-cell {
            padding-left: 10px;

            &.data-list-cell-right-offset {
                padding-right: 10px;
            }

            &.data-list-cell-change {
                padding-right: 28px;
                position: relative;

                .increase-value,
                .decrease-value {
                    margin-left: 16px;
                    position: absolute;
                    right: 0;
                    top: 6px;
                    margin-left: 0;
                }
            }

            .difference-highlight {
                margin: 0 -8px;
            }
        }

        .data-list-cell-icon {
            padding-left: 0;
        }
    }

    .history-info-head {
        align-items: center;
        border-radius: 0;
        padding: 4px 24px;
        border-left: none;
    }

    .history-status {
        position: relative;
        margin-left: 16px;
        padding-left: 20px;
        font-size: 12px;

        &:before {
            position: absolute;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 2px;
            transform: translateY(-50%);
            top: 50%;
            left: 0;
        }

        &-added {
            &:before {
                background-color: $added;
            }
        }

        &-updated {
            &:before {
                background-color: $updated;
            }
        }

        &-removed {
            text-decoration: line-through;

            &:before {
                background-color: $removed;
            }
        }
    }
}
